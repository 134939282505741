//fonts:
$main_font:'Open Sans', sans-serif;
$special_font:'Muli', sans-serif;

//default colors:
$white:#ffffff;
$border_color:#d7dee6;

$accent_color1:#00aeef;
$accent_color2:#0c4da2;
$accent_color3:#00aca8;
$accent_color4:#082e6f;

$header_color:#0c2a5f;
$font_color:#546686;
$font_color2:#90a1ae;

$theme_color1:#f5f7f9;
$theme_color2:#182743;

.small1{
    zoom: 0.99;
    -moz-transform: scale(0.99);
    -moz-transform-origin: 0 0;
}

.small2{
    zoom: 0.97;
    -moz-transform: scale(0.97);
    -moz-transform-origin: 0 0;
}

.big1{
    zoom: 1.01;
    -moz-transform: scale(1.01);
    -moz-transform-origin: 0 0;
}

.big2{
    zoom: 1.03;
    -moz-transform: scale(1.03);
    -moz-transform-origin: 0 0;
}

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin shadow {
    -webkit-box-shadow: 0px 0px 14px 10px rgba(242,244,249,1);
    -moz-box-shadow: 0px 0px 14px 10px rgba(242,244,249,1);
    box-shadow: 0px 0px 14px 10px rgba(242,244,249,1);
}

@mixin bg_pos {
    background-repeat: no-repeat;
    background-position: center;
}

@mixin anim {
    transition: 0.3s all ease;
}

body{
    font-family: $main_font;
    color: $font_color;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
        font-weight: 400;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

ul.multi_list{
    margin-top:10px;
    margin-left:3px;
}
ul.multi_list li {
    font-weight: bold;
    font-size: 14px;
    margin-bottom:3px;
}

.more_btn{
    text-decoration: none;
    font-size: 10px;
    line-height: 10px;
    font-weight: 800;
    text-transform: uppercase;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url('./../img/read_more_arr.png');
    padding-right: 32px;
    display: inline-block;
    @include anim;
    color: $header_color;
    &:hover{
        text-decoration: none;
        padding-right: 42px;
    }
}

.page{
    border-top: 3px solid $accent_color3;
    overflow: hidden;
    .contrast1{
        display: block;
    }
    .contrast2{
        display: none;
    }
    &.contrast_on{
        .rule{
            &.active{
                border: 1px solid red !important;
            }
        }
        border-top: 3px solid yellow;
        .contrast1{
            display: none;
        }
        .contrast2{
            display: block;
        }
        .textarea{
            color: yellow !important;
        background-color: red !important;
        border: none !important;
        }
        color: yellow !important;
        .logotypes{
            display: none;
        }
        .fonts{
            color: yellow !important;
        }
        .logo_box{
            img{
                opacity: 0;
            }
        }
        .date{
            color: yellow !important;
        }
         div.desc:after{
            display:none !important;
        }
        p{
            color: yellow !important;
        }
        h1,h2,h3,h4,h5,h6,a{
            color: red !important;
        }
        background: #000000 !important;
        .footer{
            background: #000000 !important;
        }
        .news{
            background: #000000 !important;
        }
        .start_register{
            background: #000000 !important;
        }
        main, footer, section {
            img{
                opacity: 0;
            }
        }
        div.default_font, .desc{

        }
        div{
            box-shadow: none;
        }
        .shape_header2, .shape_header{
            &:after{
                display: none !important;
            }
        }
        a, li{
            background-image: none !important;
        }
        .slider{
            .image, .slides{
                background-image: none !important;
                background-color: #000000 !important;
                border: none !important;
                > div{
                    background-image: none !important;
                    background-color: #000000 !important;
                }
            }
            .arr{
                background-color:#000000 !important;
            }
        }

        section.map{
            opacity: 0;
            visibility: hidden;
        }
        .submit, .submit2, .c_submit, .save_input, .submit_btn{
            background-image: none !important;
            background-color: red;
            color: yellow !important;
            border: none !important;
            box-shadow: none !important;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: yellow !important;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            color: yellow !important;
          }
          :-ms-input-placeholder { /* IE 10+ */
            color: yellow !important;
          }
          :-moz-placeholder { /* Firefox 18- */
            color: yellow !important;
          }
          .chosen-container-single .chosen-single{
              background: #000000 !important;
          }
           label{
               color: yellow !important;
               span{
                   color: red !important;
               }
           }
           main, .slider_content, .top, .text_top {
            .image, .desc, div, p{
                background-image: none !important;
                background-color: #000000 !important;

                box-shadow: none !important;
                > div{
                    background-image: none !important;
                    background-color: #000000 !important;

                }
            }
        }
        div{
            border: none !important;
        }
        header.header {
            div.header_bottom {
                nav.menu {
                    > ul {
                        > li {
                            > div {
                                > a{
                                    &:after{
                                        border-bottom: 2px solid yellow;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .input, .add_info_box, .textarea{
            color: yellow !important;
            background-color: red !important;
            border: none !important;
            > div{
                color: yellow !important;
            background-color: red !important;
            border: none !important;
            }
        }
    }
}

.default_font{
    font-size: 14px;
    line-height: 30px;
    @media only screen and (max-width: 1366px) {
        font-size: 12px;
        line-height: 28px;
    }
    h1{
        font-size: 50px;
        line-height: 60px;
        @media only screen and (max-width: 1366px) {
            font-size: 31px;
            line-height: 44px;
        }
    }
    h2{
        font-size: 31px;
        line-height: 44px;
        @media only screen and (max-width: 1366px) {
            font-size: 27px;
            line-height: 37px;
        }
    }
    h3{
        font-size: 27px;
        line-height: 37px;
        @media only screen and (max-width: 1366px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 1366px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    h5{
        font-size: 20px;
        line-height: 30px;
        @media only screen and (max-width: 1366px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1366px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    strong{
        font-weight: 900;
    }
    ul{
        padding-left: 18px;
        list-style: disc;
    }
    ol{
        padding-left: 18px;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,p,img{
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

header.header{
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    z-index: 9999;
    @media only screen and (max-width: 1200px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
        .a{
            display: flex;
            flex-flow: column;
            > div{
                align-items: stretch;
                &:first-child{
                    order: 2;
                }
                &:last-child{
                    order: 1;
                }
            }
        }
    }
    div{
        &.logo_box{
            position: relative;
            @media only screen and (max-width: 1366px) {
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 20px;
                img{
                    max-width: 100px;
                    height: auto;;
                }
                div.rwd_btn{
                    width: 30px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    > div{
                        width: 100%;
                        height: 3px;
                        margin-bottom: 3px;
                        background: $accent_color1;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.header_top{
            color: $header_color;
            //margin-bottom: 50px;
            margin-bottom: 30px;
            @media only screen and (max-width: 1366px) {
               // margin-bottom: 25px;
                margin-bottom: 15px;
            }
            @media only screen and (max-width: 1200px) {
                margin-bottom: 15px;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
                text-align: right;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    &.logotypes{
                        margin-right: 40px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 14px;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 15px;
                        }
                        > ul{
                            > li{
                                margin-right: 20px;
                                @media only screen and (max-width: 1200px) {
                                    margin-right: 0;
                                }
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    width: 20%;
                                    text-align: center;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    &.social{
                        margin-right: 20px;
                        a{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 10px;
                            &:last-child{
                                margin-right: 0;
                            }
                            img{
                                height: 15px;
                                width: auto;
                            }
                        }
                    }
                    &.acces{
                        margin-right: 35px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 14px;
                        }
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        > ul{
                            > li{
                                &.fonts{
                                    margin-right: 17px;
                                    span{
                                        cursor: pointer;
                                        &.font_normal{
                                            font-size: 12px;
                                            margin-right: 2px;
                                        }
                                        &.font_large{
                                            font-size: 16px;
                                        }
                                    }
                                }
                                &.contrast{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    &.login{
                        a{
                            font-size: 12px;
                            font-weight: 600;
                            font-family: $special_font;
                            color: $header_color;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        &.header_bottom{
            position: relative;
            z-index: 3;
            @media only screen and (max-width: 991px) {
                .has_more{
                    > div{
                        > a{
                            display: inline-block;
                            padding-right: 15px;
                            cursor: pointer;
                            &:before{
                                position: absolute;
                                top: 0;
                                right: 0;
                                height: 100%;
                                width: 10px;
                                content: '>';
                                display: inline-block;
                                transform: rotate(90deg);
                            }

                        }
                    }
                }
            }
            nav.menu{
                @media only screen and (max-width: 991px) {
                    margin-bottom: 15px;
                    display: none;
                }
                > ul{
                    > li{
                        //margin-right: 65px;
                        margin-right: 20px;
                        display: inline-block;
                        vertical-align: top;
                        @media only screen and (max-width: 1400px) {
                            margin-right: 20px;
                        }
                        @media only screen and (max-width: 1200px) {
                            margin-right: 10px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                            text-align: center;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        &:hover{
                            ul{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                        position: relative;
                        > ul{
                            position: absolute;
                            top: 100%;
                            left:-30px;
                            @include anim;
                            padding-top: 30px;
                            text-align: left;
                            opacity: 0;
                            visibility: hidden;
                            width: 200px;
                            @media only screen and (max-width: 991px) {
                                padding-top: 0;
                                width: 100%;
                                position: relative;
                                top: 0;
                                left: 0;
                                visibility: visible;
                                opacity: 1;
                                display: none;
                                transition: none;
                            }
                            > li{
                                width: 100%;
                                background: $theme_color1;
                                padding: 15px;
                                border-bottom: 1px solid $border_color;
                                padding-left: 30px;
                                @media only screen and (max-width: 991px) {
                                    padding-left: 0;
                                    padding: 0;
                                    background: transparent;
                                    border: none;
                                    text-align: center;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                        > div{
                            &.on{
                                a{
                                    &:after{
                                        width: 100%;
                                        left: 0;
                                    }
                                }
                            }
                        }
                         a{
                            color: $header_color;
                            font-weight: 700;
                            font-size: 13px;
                            line-height: 13px;
                            font-family: $special_font;
                            position: relative;
                            text-decoration: none;
                            @media only screen and (max-width: 1400px) {
                                font-size: 12px;
                                line-height: 12px;
                            }
                            @media only screen and (max-width: 991px) {
                                line-height: 30px;
                                font-size: 18px;
                            }
                            &:after{
                                content: '';
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                width: 0;
                                border-bottom: 2px solid $accent_color3;
                                @include anim;
                            }
                            &:hover{
                                &:after{
                                    width: 100%;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.frame{
    padding: 0 40px;
    @media only screen and (max-width: 991px) {
        padding: 0;
    }
}

@media only screen and (max-width: 991px) {
    .text-right, .text-left{
        text-align: center;
    }
    .side_left, .side_right{
        padding: 0 15px !important;
    }
}

.section_header{
    margin: 50px 0;
    @media only screen and (max-width: 991px) {
        text-align: center;
        margin: 30px 0;
    }
    .text-right{
        padding-top: 20px;
    }
    div.default_font{
        font-family: $special_font;
        color: $header_color;
        strong{
            font-weight: 800;
        }
    }
}

.shape_header{
    position: relative;
    font-family: $special_font;
    &.shape_header_left{
        &:after{
            background-image: url('./../img/header_shape1.png');
            right: 100%;

        }
    }
    &:after{
        @media only screen and (max-width: 1200px) {
            display: none;
        }
        width: 54px;
        height: 60px;
        content: '';
        position: absolute;
        bottom: 100%;
        @include bg_pos;
        background-size: contain;
        margin-bottom: -10px;
    }
}

.shape_header2{
    position: relative;
    margin-bottom: 30px;
    padding-left: 80px;
    font-family: $special_font;
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
    }
    div.default_font{
        color: $header_color;
    }
    &.shape_header_left{

        &:after{
            background-image: url('./../img/header_shape1.png');
            left: 0;
        }
    }
    &.shape_header_right{

        &:after{
            background-image: url('./../img/header_shape2.png');
            left: 0;
        }
    }
    &:after{
        width: 54px;
        height: 60px;
        content: '';
        position: absolute;
        top: 0%;
        @include bg_pos;
        background-size: contain;
        margin-top: -10px;

    }
}

section.slider{
    div.slides{
        height: 580px;
        width: 100%;
        background: $accent_color2;
        position: relative;
        @media only screen and (max-width: 1366px) {
            height: 480px;
        }
        @media only screen and (max-width: 991px) {
            height: 300px;
        }
        > ul{
            @include fill;
            > li{
                @include fill;
                background-position: left center;
                background-repeat: no-repeat;
                background-image: url('./../img/slider_cover.png');
                div.image{
                    opacity: 0.5;
                    @include fill;
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-size: cover;
                }
                div.slider_content{
                    padding-top: 8%;
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                        padding-top: 30px;
                    }
                    header{
                        color:$white;
                        margin-bottom: 50px;
                        @media only screen and (max-width: 1060px) {
                            padding-left: 0;
                            &:after{
                                display: none;
                            }
                        }
                    }
                    .desc{
                        .default_font{
                            font-size:16px;
                            line-height: 32px;
                            color: rgba(255, 255, 255, 0.5);
                            @media only screen and (max-width: 991px) {
                                p{
                                    display: none;
                                }
                            }
                        }
                    }
                    .icons{
                        font-family: $special_font;
                        color: $white;
                        font-size: 15px;
                        padding-top: 40px;
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        ul{
                            li{
                                margin-bottom: 30px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                div{
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 20px;
                                    img{
                                        margin-right: 20px;
                                    }
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    &.icon_holder{
                                        border: 2px solid $accent_color3;
                                        border-radius: 50%;
                                        width: 75px;
                                        height: 0;
                                        padding-bottom: 71px;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        > div.slider_nav{
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 50px;
            @media only screen and (max-width: 991px) {
                bottom: 30px;
            }
            ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    &.arrs{
                        padding-right: 49px;
                        margin-right: 50px;
                        border-right: 1px solid rgba(255, 255, 255, 0.3);
                    }
                    .more_btn{
                        color: $white;
                    }
                }
            }
            div.arr{
                display: inline-block;
                vertical-align: top;
                width: 48px;
                height: 0;
                padding-bottom: 48px;
                cursor: pointer;
                @include bg_pos;
                &.arr_left{
                    background-color: $accent_color2;
                    background-image: url('./../img/sl_arr_right.png');
                }
                &.arr_right{
                    background-color: $accent_color1;
                    background-image: url('./../img/sl_arr_left.png');
                }
            }
        }
    }
    @media (min-width: 991px) {
        .container {
          width: 970px;
        }
      }
      @media (min-width: 1200px) {
        .container {
          width: 1040px;
        }
      }
      @media (min-width: 1366px) {
        .container {
          width: 1190px;
        }
      }
      @media (min-width: 1560px) {
        .container {
          width: 1330px;
        }
      }
}

section.movie{
    padding: 50px 0;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    div.frame{
        > div{
            display: inline-block;
            vertical-align: top;
            width: 50%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &.desc_box{
                div.desc{

                    position: relative;
                    z-index: 2;
                    .default_font{
                        font-size: 18px;
                        @media only screen and (max-width: 1366px) {
                            font-size: 16px;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        @include shadow;
                        margin-bottom: 30px;
                        padding: 30px 20px;
                        text-align: center;
                    }
                    &:after{
                        z-index: -1;
                        content:'';
                        position: absolute;
                        top: 0;
                        left: -60px;
                        height: 100%;
                        width: calc(100% + 45px);
                        @include shadow;
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                    > div{
                        margin-left: -60px;
                        position: relative;
                        margin-top: 35px;
                        padding:54px 44px;
                        width: calc(100% + 100px);
                        background: $white;
                        z-index: 3;
                        @media only screen and (max-width: 991px) {
                            margin-left: 0;
                            width: 100%;
                            padding: 0;
                            margin-top: 0;
                        }
                        > div{
                            padding-left: 40px;
                            border-left: 3px solid $accent_color1;
                            margin-bottom: 50px;
                            @media only screen and (max-width: 991px) {
                                padding: 20px;
                                border-left: none;
                                border-top: 3px solid $accent_color1;
                                margin-bottom: 20px;
                            }
                            .section_header{
                                margin-top: 0;
                                margin-bottom: 30px;

                            }
                        }
                        .more_btn{
                            margin-left: 40px;
                            @media only screen and (max-width: 991px) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
            &.movie_box{
                height: 0;
                padding-bottom: 27.5%;
                //@include bg_pos;
                //background-size: cover;
                //background-image: url('./../img/movie_img.jpg');
                position: relative;
                @media only screen and (max-width: 991px) {
                    padding-bottom: 55%;
                }
                iframe{
                    @include fill;
                }
            }
        }
    }
}

section.news, .other_news{
    background-color: $theme_color1;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url('./../img/squares_bg.png');
    &.main_news{
        background-image: none;
        padding: 60px 0;
    }
    div.news_list{
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 48%;
                margin-right: 4%;
                margin-bottom: 4%;
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 30px;
                }
                &:nth-child(2n), &:last-child{
                    margin-right: 0;
                }
                a{
                    text-decoration: none;
                }
                article{
                    display: table;
                    min-height: 275px;
                    @include anim;
                    width: 100%;
                    @media only screen and (max-width: 991px) {
                        display: block;
                        min-height: auto;
                    }
                    &:hover{
                        transform: scale(1.1);
                    }
                    div{
                        display: table-cell;
                        vertical-align: top;
                        height: 100%;
                        @media only screen and (max-width: 991px) {
                            display: block;
                        }
                        &.image{
                            width: 275px;
                            @include bg_pos;
                            background-size: cover;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                height: 0;
                                padding-bottom: 100%;;
                            }
                        }
                        &.desc{
                            width: calc(100% - 275px);
                            background: $white;
                            padding:50px 30px;
                            font-size: 12px;
                            line-height: 22px;
                            color: $font_color;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                text-align: center;
                            }
                            header{
                                margin-bottom: 20px;
                                font-family: $special_font;
                                .date{
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    font-size: 12px;
                                    color: $font_color;
                                    margin-bottom: 15px;
                                    font-family: $main_font;
                                }
                                h3{
                                    font-size: 18px;
                                    line-height: 28px;
                                }
                                color: $header_color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.other_events, .other_news{
    padding-bottom: 30px !important;
    background: $theme_color1;
    padding-top: 0 !important;
}


section.events, .other_events{
    padding-bottom: 90px;
    &.main_events{
        padding-bottom: 60px;
        @media only screen and (max-width: 1366px) {
            padding-bottom: 45px;
        }
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
        }
        div.events_holder{
            margin: 0 -15px;
            > .row{
                width: 100%;
                display: flex;
                margin: 0;
                @media only screen and (max-width: 1200px) {
                    width: auto;
                    flex-flow: column;

                }
                > div{
                    float: none;
                    display: flex;
                    @media only screen and (max-width: 1200px) {
                        float: left;
                        width: 100%;
                    }
                    @media only screen and (max-width: 1200px) {
                        align-items: stretch;
                        &:first-child{
                            order: 2;
                        }
                        &:last-child{
                            order: 1;
                            margin-bottom: 30px;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        &.map_holder{
                            padding: 0 60px;
                            .mapid{
                                padding-bottom: 300px !important;;
                            }
                        }
                    }
                }
            }
            .event_list{
                width: 100%;
                ul{
                    display: flex;
                    width: calc(100% + 25px);
                    flex-flow: row;
                    flex-wrap: wrap;
                    height: 100%;
                    margin: -25px;
                    @media only screen and (max-width: 1366px) {
                        margin: -15px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin: 0;
                    }
                    li{
                        padding: 25px;
                        width: 50%;
                        margin: 0;

                        height: calc(50% + 25px);
                        position: relative;
                        @media only screen and (max-width: 1366px) {
                            padding: 15px;
                        }
                        @media only screen and (max-width: 1200px) {
                            height: 430px;
                            margin-bottom: 30px;
                            padding: 0 15px;
                        }
                        @media only screen and (max-width: 991px) {
                            padding: 0;
                            width: 100%;
                        }
                        a{
                            width: 100%;
                        }
                        article{
                            border: 1px solid #d7dee6;
                            display: flex;
                            height: 100%;
                            flex-flow: row;
                            flex-wrap: wrap;
                            div{
                                width: 100%;
                                &.image{
                                    height: calc(100% - 200px);
                                    @media only screen and (max-width: 1366px) {
                                        height: calc(100% - 150px);
                                    }
                                }
                                &.desc{
                                    border: none;
                                    height: 200px;
                                    @media only screen and (max-width: 1366px) {
                                        height: 150px;
                                        padding: 15px;
                                    }
                                    p{
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        @media only screen and (max-width: 1366px) {
                                            -webkit-line-clamp: 2;
                                        }
                                    }
                                    header{
                                        h3{
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
        .more_events{
            padding-top: 50px;
        }
    }
    div{
        &.image{
//            height: 220px;
            height: 0;
            padding-bottom: 50%;
            width: 100%;
            @include bg_pos;
            background-size: cover;
        }
        &.desc{
            background: $white;
            padding:30px;
            font-size: 12px;
            line-height: 22px;
            color: $font_color;
            border: 1px solid $border_color;
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
            header{
                margin-bottom: 20px;
                font-family: $special_font;
                .date{
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: $font_color;
                    margin-bottom: 15px;
                    font-family: $main_font;
                }
                h3{
                    font-size: 18px;
                    line-height: 28px;
                }
                color: $header_color;
            }
        }
        &.filters{
            background: $accent_color4;
            margin-bottom: 60px;
            @media only screen and (max-width: 1366px) {
                margin-bottom: 45px;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            @media (min-width: 991px) {
                .container {
                  width: 970px;
                }
              }
              @media (min-width: 1200px) {
                .container {
                  width: 1040px;
                }
              }
              @media (min-width: 1366px) {
                .container {
                  width: 1190px;
                }
              }
              @media (min-width: 1560px) {
                .container {
                  width: 1330px;
                }
              }
            .filter_holder{
                font-family: $special_font;
                font-size: 15px;
                line-height: 15px;
                color: $white;
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    &:first-child{
                        width: 195px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            position: relative;
                            .filter_btn{
                                width: 30px;
                                position: absolute;
                                top: 0;
                                right: 0;
                                > div{
                                    width: 100%;
                                    height: 3px;
                                    margin-bottom: 3px;
                                    background: $accent_color1;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                    &:last-child{
                        width: calc(100% - 195px);
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &.filter_list{
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        > ul{
                            > li{
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 30px;
                                padding-right: 30px;
                                position: relative;
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    padding-right: 0;
                                    margin-bottom: 10px;
                                    padding-bottom: 10px;
                                    width: 100%;
                                    text-align: center;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                                &:hover{
                                    > div{
                                        a{
                                            color: $white;
                                            &:after{
                                                width: 100%;
                                                left: 0;
                                            }
                                        }
                                    }
                                }
                                &:after{
                                    content: '';
                                    position: absolute;
                                    border-right: 1px solid #064c8d;
                                    top: 25%;
                                    height: 50%;
                                    right: 0;
                                    @media only screen and (max-width: 991px) {
                                        top: auto;
                                        bottom: 0;
                                        left: 0;
                                        width: 100%;
                                        height: auto;
                                        border-radius: none;
                                        border-bottom: 1px solid #064c8d;
                                    }
                                }
                                > div{
                                    a{
                                        cursor: pointer;
                                        position: relative;
                                        padding: 30px 0;
                                        display: block;
                                        text-align: none;;
                                        color: #93a5c3;
                                        text-decoration: none;
                                        @include anim;
                                        @media only screen and (max-width: 991px) {
                                            padding: 15px 0;
                                        }
                                        &:after{
                                            content: '';
                                            border-bottom: 2px solid $accent_color1;
                                            width: 0%;
                                            left: 50%;
                                            @include anim;
                                            position: absolute;
                                            bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.event_list{
            > ul{
                &.morelist{
                    li, li:nth-child(3n){
                        width: 22.555%;
                        margin-right: 2.715%;
                        @media only screen and (max-width: 1200px) {
                            width: 47.966%;
                            margin-right: 4%;
                            margin-bottom: 30px;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-right: 0;
                        }
                        &:last-child, &:nth-child(4n){
                            margin-right: 0;
                        }
                    }
                }
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 31%;
                    margin-right: 3.5%;
                    margin-bottom: 3.5%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 30px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    &:last-child, &:nth-child(3n){
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

section.coach{
    padding-bottom: 60px;
    @media only screen and (max-width: 1366px) {
        padding-bottom: 45px;
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    div.coach_list{
        > ul{
            > li{
                > a{
                    text-decoration: none;
                }
                &:hover{
                    div.image{
                        > div{
                            transform: scale(1.05);
                            opacity: 1 !important;
                        }
                    }
                }
                &:nth-child(odd){
                    div{
                        &.image{
                            background-color: $accent_color1;
                        }
                        &.desc{
                            &:after{
                                background-image: url('./../img/triangle_cover_top.png');
                                right: 100%;
                                border-top: 1px solid $border_color;
                                top: -1px;
                            }
                        }
                    }
                }
                &:nth-child(even){
                    div{
                        &.image{
                            background-color: $accent_color2;
                        }
                        &.desc{
                            &:after{
                                background-image: url('./../img/triangle_cover_top.png');
                                left: 100%;
                                border-top: 1px solid $border_color;
                                bottom: -1px;
                                -moz-transform: scale(-1, -1);
                                -webkit-transform: scale(-1, -1);
                                -o-transform: scale(-1, -1);
                                -ms-transform: scale(-1, -1);
                                transform: scale(-1, -1);
                            }
                        }
                    }
                }
               div.table_box{
                    display: table;
                    width: 100%;
                    min-height: 250px;
                    @media only screen and (max-width: 991px) {
                        min-height: auto;
                        display: flex;
                        flex-flow: column;
                    }
                    > div{
                        width: 50%;
                        height: 100%;
                        vertical-align: top;
                        display: table-cell;
                        @media only screen and (max-width: 991px) {
                            display: block;
                            align-items: stretch;
                            width: 100%;
                        }
                        &.image{
                            overflow: hidden;
                            position: relative;
                            @media only screen and (max-width: 991px) {
                                order: 1;
                                width: 100%;
                                height: 0;
                                padding-bottom: 50%;
                            }
                            > div{
                                opacity: 0.5;
                                @include bg_pos;
                                @include fill;
                                background-size: cover;
                                @include anim;
                            }
                        }
                        &.desc{
                            position: relative;
                            z-index: 2;
                            border: 1px solid $border_color;
                            padding: 50px 60px;
                            .default_font{
                                font-size: 18px;
                                color: $font_color;
                                @media only screen and (max-width: 1366px) {
                                    font-size: 16px;
                                }
                            }
                            @media only screen and (max-width: 991px) {
                                order: 2;
                                padding: 20px;
                            }
                            &:after{
                                content: '';
                                position: absolute;
                                width: 35px;
                                height: 35px;
                                @include bg_pos;
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                            }
                        }
                    }
               }
            }
        }
    }
}

footer.footer{
    background: $theme_color2;
    font-family: $special_font;
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    div{
        &.footer_top{
            padding: 60px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            @media only screen and (max-width: 991px) {
                padding: 30px 0;
            }
            img{
                cursor: pointer;
                margin-top: 20px;
            }
            p{
                color: $accent_color1;
                font-size: 11px;
                line-height: 11px;
                text-transform: uppercase;
                margin-bottom: 20px;
                font-weight: 800;
            }
            a{
                font-size: 15px;
                line-height: 15px;
                text-decoration: none;
                color: $font_color;
                @media only screen and (max-width: 1366px) {
                    font-size: 12px;
                    line-height: 12px;
                }
                @include anim;
                &:hover{
                    color: $white;
                }
            }
            ul{
                //margin-top: -5px;
                li{
                    div{
                        a{
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        &.footer_bottom{
            padding: 40px 0;
            font-size: 12px;
            line-height: 12px;
            color: $font_color;
            @media only screen and (max-width: 1200px) {
                font-size: 10px;
                line-height: 10px;
            }
            @media only screen and (max-width: 991px) {
                padding: 30px 0;
            }
            nav.menu{
                text-align: center;
                @media only screen and (max-width: 991px) {
                    padding-top: 15px;
                }
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 35px;
                        @media only screen and (max-width: 1366px) {
                            margin-right: 15px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                            text-align: center;
                        }
                        &:last-child{
                            margin-right: 0;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 15px;
                            }
                        }
                        > div{
                            > a{
                                color: $font_color;
                                font-size: 12px;
                                line-height: 12px;
                                @media only screen and (max-width: 1200px) {
                                    font-size: 10px;
                                    line-height: 10px;
                                }
                                @media only screen and (max-width: 991px) {
                                    line-height: 22px;
                                }
                                @include anim;
                                text-decoration: none;
                                font-weight: 700;
                                &:hover{
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
            .text-right{
                ul{
                    margin-top: -7px;
                    > li{
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 15px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.steps{
    font-family: $special_font;
    p{
        font-size: 16px;
        display: inline-block;
        padding-left: 57px;
        background-position: left center;
        background-repeat: no-repeat;
        background-image: url('./../img/step_icon.png');
        color: $header_color;
        line-height: 20px;
        font-weight: 600;
        min-height: 20px;
        padding-top: 3px;
    }
}

section.top{
    @media (min-width: 991px) {
        .container {
          width: 970px;
        }
      }
      @media (min-width: 1200px) {
        .container {
          width: 1040px;
        }
      }
      @media (min-width: 1366px) {
        .container {
          width: 1190px;
        }
      }
      @media (min-width: 1560px) {
        .container {
          width: 1330px;
        }
      }

    .steps{
        padding-top: 15px;
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                color: $white;
                margin-right: 80px;
                @media only screen and (max-width: 1366px) {
                    margin-right: 40px;
                }
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    margin-bottom: 15px;
                    width: 100%;
                    text-align: center;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                p{
                    color: rgba(255, 255, 255, 0.5);
                    background-image: url('./../img/step_icon2.png');
                }
                &.active{

                    p{
                        font-weight: 800;
                        background-image: url('./../img/step_icon.png');
                       color: $white;
                       text-decoration: underline;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .logout_btn{
        display: inline-block;
        font-family: $special_font;
        color: $white;
        font-size: 12.5px;
        line-height: 12.5px;
        font-weight: 800;
        background: rgba(12, 42, 95, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 5px;
        padding: 24px 36px;
        text-decoration: none;
    }
    div.top_content{
        height: 240px;
        width: 100%;
        background: $accent_color2;
        padding-top: 100px;
        position: relative;
        @media only screen and (max-width: 991px) {
            text-align: center;
            min-height: 240px;
            height: auto;
            padding-bottom: 70px;
        }
        > .container{
            position: relative;
            z-index: 2;
            header{
                @media only screen and (max-width: 991px) {
                    display: inline-block;
                }
                div.default_font{
                    color: $white;
                    h1{
                        strong{
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        div.image{
            @include fill;
            @include bg_pos;
            background-size: cover;
        }
        .nav_way{
            width: 100%;
            position: absolute;
            left: 0;
            font-family: $special_font;
            bottom: 30px;
            ul{
                li{
                    font-size: 10px;
                    line-height: 10px;
                    display: inline-block;
                    vertical-align: top;
                    font-weight: 800;
                    &:last-child{
                        a{
                            color: #a9b8db;
                            text-decoration: underline;
                        }
                        &:after{
                            display: none;
                        }
                    }
                    &:after{
                        content: '-';
                        margin: 0 10px;
                        font-size: 14px;
                    }
                    a{
                        color: #647fc0;
                        text-decoration: none;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }
            }
        }
        a.go_to_calendar{
            margin-top: 10px;
            text-decoration: none;
            font-family: $special_font;
            display: inline-block;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 800;
            color: $white;
            padding-left: 47px;
            background-position: left center;
            background-repeat: no-repeat;
            background-image: url('./../img/choice_icon3.png');
            min-height: 30px;
            line-height: 30px;
            background-size: auto 100%;
        }
    }
}

section.category{
    padding: 100px 0 40px 0;
    @media only screen and (max-width: 1366px) {
        padding: 50px 0 20px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .category_list{
        > ul{
            > li{
                width: 48%;
                margin-right: 4%;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 4%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                @include anim;
                &:hover{
                    @include shadow;
                    transform: scale(1.05);
                    div.image{
                        background-color: $accent_color1;
                        > div.hovered{
                            opacity: 1 !important;
                            visibility: visible !important;
                        }
                        > div.unhovered{
                            opacity: 0 !important;
                            visibility: hidden !important;
                        }
                    }
                }
                a{
                    text-decoration: none;
                }
                &:last-child, &:nth-child(2n){
                    margin-right: 0;
                }
                div.table_box{
                    min-height: 180px;
                    width: 100%;
                    display: table;
                    border: 1px solid $border_color;
                    > div{
                        display: table-cell;
                        vertical-align: middle;
                        height: 100%;
                        &.image{
                            width: 180px;
                            position: relative;
                            border-right: 1px solid $border_color;
                            @media only screen and (max-width: 600px) {
                                width: 50%;
                            }
                            @include anim;
                            > div{
                                @include fill;
                                @include anim;
                               @include bg_pos;
                               &.unhovered{
                                opacity: 1;
                                visibility: visible;
                               }
                               &.hovered{
                                   visibility: hidden;
                                   opacity: 0;
                               }
                            }
                        }
                        &.desc{
                            width: calc(100% - 180px);
                            padding: 0 40px;
                            font-size: 20px;
                            line-height: 30px;
                            font-family: $special_font;
                            color: $header_color;
                            font-weight: 700;
                            @media only screen and (max-width: 600px) {
                                width: 50%;
                                padding: 0 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.text_top{
    @media (min-width: 991px) {
        .container {
          width: 970px;
        }
      }
      @media (min-width: 1200px) {
        .container {
          width: 1040px;
        }
      }
      @media (min-width: 1366px) {
        .container {
          width: 1190px;
        }
      }
      @media (min-width: 1560px) {
        .container {
          width: 1330px;
        }
      }
    div.image{
       // height: 580px;
        height: 290px;
        width: 100%;
        @include bg_pos;
        background-size: cover;
        position: relative;
        background-position: center top;
        //@media only screen and (max-width: 1366px) {
        //    height: 400px;
        //}
        //@media only screen and (max-width: 991px) {
        //    height: 300px;
        //}
        > div.text_top_content{
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 50px;
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            header.shape_header{
                @media only screen and (max-width: 991px) {
                    display: inline-block;
                }
                div.date{
                    margin-bottom: 20px;
                    p{
                        display: inline-block;
                        padding: 5px 18px;
                        background: rgba(0, 0, 0, 0.3);
                        text-transform: uppercase;
                        color: $white;
                        font-size: 11px;
                    }
                }
                div.default_font{
                    color: $white;
                    h1{
                        font-size: 37px;
                        line-height: 47px;
                    }
                }
            }
            .text-right{
                padding-top: 5%;
                .more_btn{
                    padding-right: 0;
                    padding-left: 32px;
                    background-image: url('./../img/more_btn2.png');
                    color: $white;
                    background-position: left center;
                }
            }
        }
    }
}

section.text_page{
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    div.desc{
        padding-bottom: 60px;
        border-bottom: 1px solid $border_color;
        div.default_font{
            h1,h2,h3,h4,h5,h6{
                color: $header_color;
            }
        }
    }
    div.galery{
        border-bottom: 1px solid $border_color;
        padding-bottom: 30px;
        div.galery_list{
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 23%;
                    margin-right: 2.6%;
                    margin-bottom: 2.6%;
                    overflow: hidden;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        margin-bottom: 15px;
                        width: 100%;
                    }
                    &:hover{
                        div.image{
                            transform: scale(1.05);
                        }
                    }
                    &:nth-child(4n), &:last-child{
                        margin-right: 0;
                    }
                    div.image{
                        width: 100%;
                        height: 0;
                        padding-bottom: 74%;
                        position: relative;
                        @include bg_pos;
                        background-size: cover;
                        @include anim;
                    }
                }
            }
        }
    }
    div.download{
        padding-bottom: 60px;
        @media only screen and (max-width: 1366px) {
            padding-bottom: 45px;
        }
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
        }
        div.download_list{
            > ul{
                > li{
                    border: 1px solid $border_color;
                    border-radius: 7px;
                    margin-bottom: 10px;
                    @include anim;
                    a{
                        text-decoration: none;
                    }
                    &:hover{
                        transform: scale(1.03);
                        @include shadow;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    div.file_box{
                        padding-left: 80px;
                        background-position: left 20px center;
                        background-repeat: no-repeat;
                        background-image: url('./../img/file_icon.png');
                        height: 62px;
                        line-height: 62px;
                        font-family: $special_font;
                        color: $header_color;
                        > div{
                            font-size: 12px;
                            padding-right: 60px;
                            background-repeat: no-repeat;
                            background-image: url('./../img/download_icon.png');
                            background-position: right 20px center;
                           p{
                               font-weight: 700;
                               span: {
                                   font-weight: 400;
                               }
                           }
                        }
                    }
                }
            }
        }
    }
    div.application_form{
        min-height: 660px;
        display: flex;
        @include shadow;
        margin-bottom: 60px;
        .rules_list{
            .rule{
                cursor: default;
            }
        }
        @media only screen and (max-width: 1366px) {
            margin-bottom: 45px;
        }
        @media only screen and (max-width: 991px) {
            min-height: auto;
            display: block;
            margin-bottom: 30px;
        }
        > div{
            width: 50%;
            padding: 50px 60px;
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding: 30px 15px;
            }
            &:first-child{
                @include bg_pos;
                background-size: cover;
                header.section_header{
                    margin-top: 0;
                    div.default_font{
                        color: $white;
                        h2{
                            line-height: 50px;
                        }
                    }
                }
                .default_font{
                    color: #849fc2;
                }
            }
            &:last-child{
                header{
                    margin-bottom: 30px;
                    div.default_font{
                        color: #e23d3d;
                        strong{
                            color: $header_color;
                        }
                        strong{
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    div.text_option{
        font-family: $special_font;
        color: $header_color;
        font-size: 12.5px;
        line-height: 12.5px;
        padding: 0 35px;
        border: 1px solid $border_color;
        @include shadow;
        line-height: 91px;
        font-weight: 700;
        a,p{
            font-weight: inherit;
            display: inline-block;
            color: $header_color;
            &.go_up{
                cursor: pointer;
            }
            &.print_btn{
                padding-left: 47px;
                background-position: left center;
                background-repeat: no-repeat;
                background-image: url('./../img/print_icon.png');
                text-decoration: none;
            }
        }

        div.share{
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                   // margin-right: 30px;
                   margin-left: 30px;
                   &:first-child{
                       margin-left: 0;
                   }
                }
            }
        }
    }
}

.share{
    height:93px;
    .fb-share-button{
        top:-36px; margin-left: 0;
        @media only screen and (max-width: 991px) {
            top: -80px;
            margin-left: 0;
        }
    }
}

.coach_dashboard{
    .file_input{
        .file_box{
           // @include fill;
            opacity: 0;
        }
        .file_field{
            //@include fill;
            opacity: 0;
            input{
                //@include fill;
            }
        }
    }

    .cards{
        .commit1{
            margin-left: 25px;
            padding-bottom: 14px;
            margin-bottom:15px;
            border-bottom: 1px solid $border_color;
            @media only screen and (max-width: 991px) {
                margin-left: 0;
                margin-top: 30px;
            }
            h3{
                color: $header_color;
                strong{
                    font-weight: 600;
                }
            }
        }
        .c_list{
            margin-bottom: 60px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
        label{

        }
        div.holder{
            text-align: left;
            &:last-child{
                margin-bottom: 15px;
            }
        }
      }
}

.default_form{
    font-family: $special_font;
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $font_color2;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: $font_color2;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: $font_color2;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: $font_color2;
      }
      .holder{
          margin-bottom: 30px;
          &.opinion{
            &:last-child{
                margin-bottom: 50px;
            }
          }
          &:last-child{
              margin-bottom: 0;
          }
      }
    label{
        font-weight: 700;
        color: $header_color;
        font-size: 11px;
        line-height: 11px;
        margin-bottom: 15px;
    }
    div.submit_btn{
        margin-top: 10px;
        height: 55px;
        width: 100%;
        color: $white;
        font-weight: 800;
        font-size: 12.5px;
        line-height: 55px;
        background: $accent_color2;
        border-radius: 5px;
        position: relative;
        text-align: center;
        p{
            display: inline-block;
            padding-right: 34px;
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url('./../img/read_more_arr.png');

        }
    }
    .more_btn{
        height: 55px;
        line-height: 55px;
        border: 1px solid $border_color;
        padding:0 20px;
        width:100%;
        background-position: right 20px center;
        border-radius: 5px;
    }
    .add_info_box{
        color: $header_color;
        height: 55px;
        line-height: 55px;
        border: 1px solid $border_color;
        background: #f6fafb;
        padding:0 20px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        position: relative;
        overflow: hidden;
        &.complete{
            &:after{
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                border-bottom: 4px solid #36b633;
                width: 100%;
            }
        }
        &.uncomplete{
            &:after{
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                border-bottom: 4px solid #b62e2e;
                width: 100%;
            }
        }
    }
    input{
        width: 100%;
        outline: none;
        &.input, &.field_companydata_form_answer_other{
            color: $font_color2;
            height: 55px;
            border: 1px solid $border_color;
            background: #f6fafb;
            padding:0 20px;
            border-radius: 5px;
            font-size: 12px;
        }
        &.submit{
            @include fill;
            opacity: 0;
        }
        &.submit2{
            height: 55px;
            width: 100%;
            line-height: 55px;
            color: $header_color;
            font-weight: 800;
            border: 1px solid $accent_color3;
            border-radius: 5px;
            font-size: 12.5px;
            line-height: 12.5px;
            @media only screen and (max-width: 991px) {
                margin-top: 15px;
            }
        }
    }
    .textarea{
        color: $font_color2;
        min-height: 145px;
        border: 1px solid $border_color;
        background: #f6fafb;
        padding:20px;
        border-radius: 5px;
        font-size: 12px;
        outline: none;
        resize: none;
        width: 100%;
    }
    .dz-preview{
        display: none !important;
    }
                .file_input{
                        display: inline-block;
                        vertical-align: top;
                        border-radius: 8px;
                        width: 14%;
                        cursor: pointer;
                        height: 60px;
                        border: 1px solid $border_color;
                        font-family: $special_font;
                        color: $font_color;
                        font-weight: 700;
                        text-align: center;
                        //line-height: 60px;
                        font-size: 12px;
                        position: relative;
                        @media only screen and (max-width: 1200px) {
                            width: 20%;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                        .dz-message {
                            font-size: 13px;
                            line-height: 20px;
                            padding-top: 8px;
                            @media only screen and (max-width: 1200px) {
                                font-size: 11px;
                                line-height: 18px;
                                padding-top: 10px;
                                @media only screen and (max-width: 1200px) {
                                    padding-top: 0;
                                    line-height: 60px;
                                }
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 15px;
                        }
                        > input{
                            @include fill;
                            opacity: 0;
                            cursor: pointer;
                        }
                    }
                .like_input{
                        white-space: nowrap;
                        overflow: hidden;
                        width: 83%;
                        margin-left: 2%;
                        display: inline-block;
                        vertical-align: top;
                        color: $font_color2;
                        height: 60px;
                        border: 1px solid $border_color;
                        background: #f6fafb;
                        padding:0 20px;
                        border-radius: 5px;
                        font-size: 12px;
                        line-height: 60px;
                        font-weight: 700;
                        @media only screen and (max-width: 1200px) {
                            width: 77%;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-left: 0;
                            width: 100%;
                        }
                        > div{
                            width: 100%;
                            > div{
                                display: inline-block;
                                vertical-align: top;
                            }
                        }
                        &.answer{
                            background: transparent;
                            padding: 0 13px;
                            height: 55px;
                            line-height: 55px;
                            cursor: pointer;
                            margin-bottom: 50px;
                            &.active{
                                border: 1px solid $accent_color3;
                                .box{
                                    border: 1px solid $accent_color3;
                                }
                            }
                            > div{
                                display: inline-block;
                                vertical-align: middle;
                                &.box{
                                    width: 11px;
                                    height: 0;
                                    padding-bottom: 9px;
                                    border: 1px solid $border_color;
                                    position: relative;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    > div{
                                        @include fill;
                                        @include bg_pos;
                                        background-size: cover;
                                        &.yes{
                                            &.on{
                                                background-image: url('./../img/check_box_dot.png');
                                            }
                                        }
                                    }
                                }
                                &.default_font{
                                    width: calc(100% - 11px);
                                    padding-left: 15px;
                                    line-height: auto;
                                }
                            }
                        }
                    }
    /*.file_input*/ .dropzone_box{
        display: inline-block;
        vertical-align: top;
        border-radius: 8px;
        width: 14%;
        cursor: pointer;
        height: 60px;
        border: 1px solid $border_color;
        font-family: $special_font;
        color: $font_color;
        font-weight: 700;
        text-align: center;
        //line-height: 60px;
        font-size: 12px;
        position: relative;
        @media only screen and (max-width: 1200px) {
            width: 20%;
        }
        @media only screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 10px;
        }
        .dz-message {
            font-size: 13px;
            line-height: 20px;
            padding-top: 8px;
            @media only screen and (max-width: 1200px) {
                font-size: 11px;
                line-height: 18px;
                padding-top: 10px;
                @media only screen and (max-width: 1200px) {
                    padding-top: 0;
                    line-height: 60px;
                }
            }
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 15px;
        }
        > input{
            @include fill;
            opacity: 0;
            cursor: pointer;
        }
    }
    /*.like_input*/ .upload_progres_bars{

    display:none!important;

        white-space: nowrap;
        overflow: hidden;
        width: 83%;
        margin-left: 2%;
        display: inline-block;
        vertical-align: top;
        color: $font_color2;
        height: 60px;
        border: 1px solid $border_color;
        background: #f6fafb;
        padding:0 20px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 60px;
        font-weight: 700;
        @media only screen and (max-width: 1200px) {
            width: 77%;
        }
        @media only screen and (max-width: 991px) {
            margin-left: 0;
            width: 100%;
        }
        > div{
            width: 100%;
            > div{
                display: inline-block;
                vertical-align: top;
            }
        }
        &.answer{
            background: transparent;
            padding: 0 13px;
            height: 55px;
            line-height: 55px;
            cursor: pointer;
            margin-bottom: 50px;
            &.active{
                border: 1px solid $accent_color3;
                .box{
                    border: 1px solid $accent_color3;
                }
            }
            > div{
                display: inline-block;
                vertical-align: middle;
                &.box{
                    width: 11px;
                    height: 0;
                    padding-bottom: 9px;
                    border: 1px solid $border_color;
                    position: relative;
                    border-radius: 50%;
                    overflow: hidden;
                    > div{
                        @include fill;
                        @include bg_pos;
                        background-size: cover;
                        &.yes{
                            &.on{
                                background-image: url('./../img/check_box_dot.png');
                            }
                        }
                    }
                }
                &.default_font{
                    width: calc(100% - 11px);
                    padding-left: 15px;
                    line-height: auto;
                }
            }
        }
    }
    .rule{
        background: #f9f9f1;
        border-radius: 10px;
        padding: 40px;
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        cursor: pointer;
        div{
            display: inline-block;
            vertical-align: top;
            &.field_checkbox{
                width:20px;
                .box{
                    margin-top: 3px;
                    border: 1px solid #cdcdab;
                    background: $white;
                    width: 20px;
                    height: 0;
                    padding-bottom: 18px;
                    border-radius: 5px;
                    position: relative;
                    > div{
                        @include fill;
                        @include bg_pos;
                        background-size: contain;
                        &.yes{
                            &.on{
                                background-image: url('./../img/check_icon.png');
                            }
                        }
                    }
                }
            }

            &.default_font{
                color: #666661;
                padding-left: 15px;
                width: calc(100% - 20px);
                font-family: $special_font;
            }
        }
    }
    .rules_list{
        padding-top: 20px;
        > ul{
            > li{
                margin-bottom: 20px;
            }
        }
    }
}

section.company_data{
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    div.data_box{
        padding: 60px;
        @media only screen and (max-width: 1366px) {
            padding: 30px;
        }
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        @include shadow;
        font-family: $special_font;
        div{
            &.left_side{
                > div{
                    margin-bottom: 30px;
                    &.company_name{
                        h2{
                            font-size: 20px;
                            line-height: 30px;
                            font-weight: 700;
                            color: $header_color;
                        }
                    }
                    &.company_contact_data{
                        font-size: 11px;
                        line-height: 31px;
                        color: $font_color2;
                        font-weight: 700;
                        > p{
                            font-weight: inherit;
                        }
                        div.default_font{
                            color: $header_color;
                            font-weight: 600;
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            &.right_side{
                padding-top: 50px;
                .consulting_card{
                    line-height: 60px;
                    height: 60px;
                    text-align: center;
                    width: 290px;
                    border: 1px solid $border_color;
                    border-radius: 8px;
                    background-position: right 30px center;
                    @media only screen and (max-width: 991px) {
                        width: 260px;
                    }
                }
            }
            &.more_info{
                padding: 60px;
                border: 1px solid $border_color;
                margin: 60px 0;
                @media only screen and (max-width: 1366px) {
                    padding: 30px;
                    margin: 30px 0;
                }
                @media only screen and (max-width: 991px) {
                    padding: 15px;
                    margin: 15px 0;
                }
                div{
                    &.boss{
                        font-size: 11px;
                        color: $font_color2;
                        header{
                            font-size: 16px;
                            line-height: 34px;
                            font-weight: 700;
                            margin-bottom: 10px;
                            h3{
                                font-size: inherit;
                                line-height: inherit;
                                font-weight: inherit;
                                color: $font_color2;
                            }
                            p{
                                font-weight: inherit;
                            }
                            color: $header_color;
                        }
                    }
                    &.fast_contact{
                        padding-top: 30px;
                        padding-left: 20px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                            padding-top: 15px;
                        }
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 60px;
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    width: 100%;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                a{
                                    text-decoration: none;
                                    font-weight: 700;
                                    color: $header_color;
                                    padding-left: 38px;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    font-size: 16px;
                                    line-height: 34px;
                                    display: inline-block;
                                    @media only screen and (max-width: 991px) {
                                        padding-left: 0;
                                        padding-top: 30px;
                                        background-position: center top;
                                    }
                                    &.phone{
                                        background-image: url('./../img/company_icon1.jpg');
                                    }
                                    &.mail{
                                        background-image: url('./../img/company_icon2.jpg');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .back_btn1{
            > div{
                font-size: 55px;
                width: 100%;
                background: $accent_color2;
                color: $white;
                font-size: 12.5px;
                line-height: 55px;
                font-weight: 700;
                text-decoration: none;
                text-align: center;
                border-radius: 5px;
                p{
                    display: inline-block;
                    padding-left: 38px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-image: url('./../img/more_btn2.png');
                }
            }
        }
    }
}

section.login{
    font-family: $special_font;
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    div.login_box{
        padding:50px 60px;
        font-size: 12px;
        line-height: 20px;
        @media only screen and (max-width: 1366px) {
            padding: 30px 30px;
        }
        @media only screen and (max-width: 991px) {
            padding:30px 15px;
        }
        div.holder{
            margin-bottom: 20px;
        }
        a{
            margin-top: 15px;
            display: inline-block;
            text-decoration: none;
            color: $font_color2;
            font-weight: 600;
        }
        @include shadow;
        .section_header{
            color: $header_color;
            margin-top: 0;
            margin-bottom: 30px;
            h2{
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}

section.contact{
    position: relative;
    z-index: 2;
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
       padding-top: 45px;
       padding-bottom: 45px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    form{
        > .row{
            margin: 0 -30px;
            > div{
                padding: 0 30px;
            }
        }
    }
    div{
        header{
            font-family: $special_font;
            color: $header_color;
            h2{
                font-size: 25px;
                line-height: 35px;
            }
            h3{
                font-size: 18px;
                line-height: 28px;
            }
            h4{
                font-size: 16px;
                line-height: 26px;
            }
        }
        &.contact_data{
            background: $white;
            padding: 60px 40px;
            @include shadow;
            @media only screen and (max-width: 1366px) {
                padding: 30px 20px;
            }
            @media only screen and (max-width: 991px) {
                padding: 30px 15px;
                text-align: center;
            }
            div.holder{
                margin: 40px 0;
            }
            div.contact_box{
                padding-top: 40px;
                ul{
                    padding-top: 20px;
                    > li{
                        width: 100%;
                        color: #a6afc0;
                        font-size: 15px;
                        line-height: 40px;
                        padding-left: 30px;
                        background-position: left center;
                        background-repeat: no-repeat;
                        @media only screen and (max-width: 991px) {
                            background-position: center top;
                            text-align: center;
                            padding-left: 0;
                            padding-top: 30px;
                            margin-bottom: 15px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        &.phone{
                            background-image: url('./../img/c_icon1.png');
                        }
                        &.mail{
                            background-image: url('./../img/c_icon2.png');
                        }
                        &.adres{
                            background-image: url('./../img/c_icon3.png');
                        }
                        a{
                            color: $header_color;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        &.contact_form{
            @media only screen and (max-width: 991px) {
                margin: 30px 0 30px 0;
            }
            header{
                margin-bottom: 40px;
            }
            div.holder{
                height: 40px;
                width: 100%;
                position: relative;
                border-bottom: 2px solid #d8e5df;
                margin-bottom: 15px;
                &.holder2{
                    height: auto;
                    min-height: 100px;
                    margin-bottom: 30px;
                    label{
                        height: auto;
                    }
                    textarea{
                        padding-top: 11px;
                        @include fill;
                        border: none;
                        resize: none;
                        outline: none;
                        font-size: 13px;
                        color: #546686;
                        font-weight: 700;
                        background: transparent;
                    }
                }

                label{
                    @include anim;
                    display: block;
                    color: #546686;
                    font-size: 13px;
                    line-height: 40px;
                    @include fill;
                    &.shrink{
                        margin-top: -22px;
                        font-size: 8px;
                    }
                    span{
                        color: $accent_color1;
                    }
                }
                input{
                    outline: none;
                    background-color: transparent;
                    &.c_input{
                        @include fill;
                        border: none;
                        font-size: 13px;
                        color: #546686;
                        font-weight: 700;
                    }
                }
            }
            .text-right{
                padding-top: 30px;
                .c_submit{
                    text-decoration: none;
                    font-size: 10px;
                    line-height: 10px;
                    font-weight: 800;
                    text-transform: uppercase;
                    background-position: right center;
                    background-repeat: no-repeat;
                    background-image: url('./../img/read_more_arr.png');
                    padding-right: 32px;
                    display: inline-block;
                    @include anim;
                    color: $header_color;
                    border: none;
                    background-color: transparent;
                    &:hover{
                        text-decoration: none;
                        padding-right: 42px;
                    }
                }
            }
        }
    }
}

section.map{
    margin-bottom: 60px;
    @media only screen and (max-width: 1366px) {
        margin-bottom: 45px;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 0;
        margin-bottom: 30px;
    }
    div.map_holder{
        width: 100%;
        height: 550px;
        position: relative;
        @media only screen and (max-width: 1366px) {
            height: 400px;
        }
        @media only screen and (max-width: 991px) {
            height: 300px;
        }
        > iframe{
            @include fill;
            border: none;
        }
    }
}

div.rate_box{
    font-family: $special_font;
    color: $font_color2;
    border: 1px solid $border-color;
    padding: 20px 10px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    h2{
        font-size: 16px;
        line-height: 36px;
        color: $header_color;
       margin-bottom: 20px;
    }
    ul.stars{
        margin-bottom: 15px;
        > li{
            margin-right: 8px;
            display: inline-block;
            vertical-align: top;
            &:last-child{
                margin-right: 0;
            }
            @include bg_pos;
            background-size: contain;
            width: 21px;
            height: 0;
            padding-bottom: 21px;
            background-image: url('./../img/star_unrated.png');
        }
    }
}

section.you_data{
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .company_logo{
        height: 0;
        padding-bottom: 100%;
        @include bg_pos;
        background-size: 50% auto;
        width: 100%;
        border: 1px solid $border_color;
        position: relative;
        div.edit{
            position: absolute;
            top: 25px;
            right: 25px;
            cursor: pointer;
        }
    }
    .fast_contact{
        padding: 30px 0;
        border-bottom: 1px solid $border_color;
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 60px;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    text-decoration: none;
                    font-weight: 700;
                    color: $header_color;
                    padding-left: 38px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    font-size: 16px;
                    line-height: 34px;
                    &.phone{
                        background-image: url('./../img/company_icon1.jpg');
                    }
                    &.mail{
                        background-image: url('./../img/company_icon2.jpg');
                    }
                }
            }
        }
    }
    .container{
        > .row{
            margin: 0 -30px;
            > div{
                padding: 0 30px;
            }
        }
    }
    div{
        &.coach_box{
            @include shadow;
            div.image{
                @include bg_pos;
                background-size: cover;
                width: 100%;
                height: 0;
                padding-bottom: 122%;
                position: relative;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                }
                div.edit{
                    position: absolute;
                    top: 25px;
                    right: 25px;
                    cursor: pointer;
                }
            }
        }
    }
}

.more_btn3{
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    color: #5e6b80;
    font-family: $special_font;
    border: 1px solid $border_color;
    padding: 18px 20px;
    width: 100%;
    text-align: center;
    text-decoration: none !important;
    font-weight: 700;
    border-radius: 8px;
}

.edit_btn{
    height: 23px;
    display: inline-block;
    padding-left: 37px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url('./../img/data_edit.png');
    text-decoration: none !important;
    color: $font_color2;
    font-weight: 700;
    font-family: $special_font;
    line-height: 23px;
    @media only screen and (max-width: 991px) {
        margin-top: 15px;
    }
    &.change_password{
        background-image: url('./../img/pass_icon.png');
        @media only screen and (max-width: 991px) {
            margin-top: 15px;
        }
    }
}

.your_box{
    padding: 60px 50px;
    @media only screen and (max-width: 1366px) {
        padding: 45px 30px;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 15px;
    }
    @include shadow;
    border: 1px solid $border_color;
    header{
        font-family: $special_font;
        margin-bottom: 30px;
        h2{
            color: $header_color;
            font-size: 20px;
            line-height: 30px;
        }
        h3{
            color: $header_color;
            font-size: 18px;
            line-height: 28px;
        }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $header_color;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: $header_color;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: $header_color;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: $header_color;
      }
    .input, .textarea{
        font-size: 12.5px;
        font-weight: 700;
        line-height: 26px;
    }
    .rate_box{
        padding: 0;
        border: none;
        margin-top: 50px;
        &.rate_coach{
            margin-top: 30px;
            .stars{
                margin-bottom: 0;
                > li{
                    width: 27px;
                    height: 0;
                    padding-bottom: 27px;
                }
            }
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: middle;
                .stars{
                    padding-left: 30px;
                    margin-bottom: 0;
                    > li{
                        width: 27px;
                        height: 0;
                        padding-bottom: 27px;
                    }
                }
                header{
                    margin-bottom: 0;
                    h2{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

div.cards{
    div.logo{
        height: 130px;
        @include bg_pos;
        background-size: 50% auto;
        width: 100%;
        border: 1px solid $border_color;
    }
    div.name{
        margin: 20px 0;
    }
    div.status{
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
                p{
                    margin-left: 15px;
                    padding: 5px 10px;
                    color: $white;
                    border-radius: 5px;
                    font-weight: 700;
                    &.complete{
                        background: #36b633;
                    }
                    &.uncomplete{
                        background: #b62e2e;
                    }
                }
            }
        }
    }
    form{
        padding-left: 25px;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
        .save_input{
            @include shadow;
            border-radius: 8px;
            cursor: pointer;
            color: $white;
            background: $accent_color2;
            height: 60px;
            border: 1px solid $border_color;
            font-family: $special_font;
            font-weight: 700;
            text-align: center;
            line-height: 60px;
            font-size: 12px;
            position: relative;
            width: 170px;
        }
    }
    .files{
        margin-bottom: 15px;
        header{
            margin-bottom: 10px;
        }
        .file_info{
            font-size: 11px;
            line-height: 11px;
            padding-top: 10px;
            color: $font_color2;
        }
    }
}

.steps_section{
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .your_box{
        margin-bottom: 60px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        .steps_boxes{
            padding-top: 50px;
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
            }
            > div{
                > div{
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .steps{
                padding-bottom: 15px;
            }
        }
    }

    .start_register{
        margin-top: 10px;
        height: 55px;
        width: 100%;
        color: $white;
        font-weight: 800;
        font-size: 12.5px;
        line-height: 55px;
        background: $accent_color2;
        border-radius: 5px;
        position: relative;
        text-align: center;
        display: block;
        p{
            font-weight: 800;
            display: inline-block;
            padding-right: 34px;
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url('./../img/read_more_arr.png');

        }
    }
}

.regon{
    display: block;
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    form{
        padding-top: 20px;
        @media only screen and (max-width: 991px) {
            padding-top: 0;
        }
    }
}

.question{
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .default_form{
        .like_input{
            margin-bottom: 0;
            @media only screen and (max-width: 991px) {
                margin-bottom: 15px;
            }
        }
        .holder{
            padding-top: 60px;
            @media only screen and (max-width: 1366px) {
                padding-top: 45px;
            }
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
            }
        }
    }
}

.form_after_question, .become_coach{
    padding: 60px 0;
    @media only screen and (max-width: 1366px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    header, .default_font{
        span{
            color: #e23d3d;
        }
    }
    .your_box{
        margin-bottom: 60px;
        @media only screen and (max-width: 1366px) {
            margin-bottom: 45px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    .data_form{
        .holder{
            margin-bottom: 30px;
        }
    }
    .status_header{
        margin-bottom: 60px;
        &.bordered{
            margin-bottom: 45px;
            padding-bottom: 45px;
            border-bottom: 1px solid $border_color;
        }
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 25px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.default_font{
                    color: $header_color;
                    strong{
                        font-weight: 700;
                    }
                }
                &.status{
                    p{
                        display: inline-block;
                        font-size: 31px;
                        line-height: 44px;
                        font-weight: 700;
                        color: $white;
                        padding: 0 15px;
                        @media only screen and (max-width: 1366px) {
                            font-size: 27px;
                            line-height: 37px;
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                        &.red{
                            background: #e23d3d;
                        }
                        &.blue{
                            background: #00aeef;
                        }
                    }
                }
            }
        }
    }
    .desc{
        margin-bottom: 40px;
    }
    .holder{
        &.email_holder{
            margin-bottom: 40px;
        }
    }
    .ansers{
       > ul{
           > li{
            margin-bottom: 20px;
           }
       }
        .like_input{
            height: auto;
            min-height: 55px;
            margin-bottom: 0;
            > div{
                vertical-align: top;
                &.box{
                    margin-top: 23px;
                }
                &.default_font{
                    line-height: 55px;
                    padding-right: 30px;
                    @media only screen and (max-width: 991px) {
                        padding-right: 10px;
                    }
                    textarea{
                        margin-bottom: 15px;
                    }
                    p{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .add_photo{
        padding-top: 40px;
    }
    .industry{
        padding-top: 40px;
        #map{
            @media only screen and (max-width: 991px) {
                height: 200px !important;
            }
        }
    }
    .competences_table{
        width: 100%;
        display: table;
        border: 1px solid $border_color;
        margin-bottom: 60px;
        @media only screen and (max-width: 1366px) {
            margin-bottom: 45px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 680px) {
            display: block;
            border: 1px solid $border_color;
        }
        .table_row{
            display: table-row;
            width: 100%;
            height: 100%;
            @media only screen and (max-width: 680px) {
                display: block;
                &:first-child{
                    > div{
                        display: none;
                        &:first-child{
                            display: block;
                        }
                    }
                }
            }
            > div{
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                text-align: center;
                font-size: 15px;
                line-height: 15px;
                padding: 17px;
                border-bottom: 1px solid $border_color;
                border-right: 1px solid $border_color;
                @media only screen and (max-width: 680px) {
                    display: block;
                    width: 100%;
                    border-right: none;
                }
                &:last-child{
                    border-right: none;
                }
                &:first-child{
                    width: 50%;
                    text-align: left;
                    @media only screen and (max-width: 680px) {
                        text-align: center;
                        width: 100%;
                    }
                }
                .box{
                    margin: 0 auto;
                    width: 27px;
                    height: 0;
                    padding-bottom: 27px;
                    position: relative;
                    cursor: pointer;
                    > div{
                        @include fill;
                        @include bg_pos;
                        background-image: url('./../img/star_unrated.png');
                        &.yes{
                            &.on{
                                background-image: url('./../img/star_rated.png');
                            }
                        }
                    }
                }
            }
        }
    }
    .agreement{
        margin-bottom: 40px;
        border: 1px solid $border_color;
        padding: 20px 40px;
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        div.default_font{
            line-height: 55px;
            @media only screen and (max-width: 991px) {
                line-height: 28px;
            }
        }
        .like_input{
            margin-bottom: 0;
            @media only screen and (max-width: 991px) {
                margin-bottom: 15px;
            }
        }
    }
    div.download{
        padding-bottom: 60px;
        div.download_list{
            > ul{
                > li{
                    border: 1px solid $border_color;
                    border-radius: 7px;
                    margin-bottom: 10px;
                    @include anim;
                    a{
                        text-decoration: none;
                    }
                    &:hover{
                        transform: scale(1.03);
                        @include shadow;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    div.file_box{
                        padding-left: 80px;
                        background-position: left 20px center;
                        background-repeat: no-repeat;
                        background-image: url('./../img/file_icon.png');
                        height: 62px;
                        line-height: 62px;
                        font-family: $special_font;
                        color: $header_color;
                        > div{
                            font-size: 12px;
                            padding-right: 60px;
                            background-repeat: no-repeat;
                            background-image: url('./../img/download_icon.png');
                            background-position: right 20px center;
                           p{
                               font-weight: 700;
                               span{
                                   font-weight: 400;
                               }
                           }
                        }
                    }
                }
            }
        }
    }
    .files_upload{
        div.files{
            margin-bottom: 25px;
            .file_info{
                padding-top: 10px;
                font-family: $special_font;
                color: #90a1ae;
                font-size: 11px;
                p{
                    font-weight: 600;
                }
            }
        }
    }
}

.calendar{
    > header{
        color: $white;
        font-size: 20px;
        line-height: 20px;
        font-family: $special_font;
        background: $theme_color2;
        padding: 30px 15px;
        text-align: center;
    }
    .mycalendar2_datepicker{
        border: 1px solid $border_color;
        .calendar_content {
            padding: 20px 40px;
            @media only screen and (max-width: 1366px) {
                padding: 15px;
            }
            header.months_change {
                border-bottom: none;
                padding-bottom: 0;
                @media only screen and (max-width: 991px) {
                    padding-bottom: 20px;
                }
                .left {
                    svg{
                        fill: $accent_color1;
                    }
                }
                .right {
                    svg{
                        fill: $accent_color1;
                    }
                }
                .title{
                    .year{
                        color: $font_color;
                        font-weight: 600;
                    }
                    .month{
                        font-family: $special_font;
                        font-weight: 800;
                        font-size: 20px;
                        line-height: 30px;
                        color: $header_color;
                    }
                }
            }
            .days_table{
                .tr{
                    .td {
                        height: auto;
                        &.active{
                            border-radius: 50%;
                            @media only screen and (max-width: 991px) {
                                background: transparent !important;
                                p{
                                    color: $accent_color1 !important;
                                }
                            }

                        }
                        p {
                            padding-top: 0;
                            color: #53688c;
                            font-weight:600;
                            font-size: 14px;
                            font-family: $special_font;
                            width: 100%;
                            height: 0;
                            padding-bottom: 100%;
                            line-height: 48px;
                            @media only screen and (max-width: 1200px) {
                                line-height: 37px;
                            }
                            @media only screen and (max-width: 991px) {
                                line-height: 50px;
                                width: auto;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
            .active{
               > p{
                color: $white !important;
               }
            }
        }
    }
}

.previewsContainer {
    .row {
        width:100%;
        background-color:#f6fafb;
        border:1px solid #d7dee6;
        padding:20px 20px 18px 20px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        margin-left:0;
        margin-right:0;

        white-space: nowrap;

        > div {
            display:inline-block;
            vertical-align: top;

            &.lp {
                width:30px;
                font-size:15px;
                display:none;
            }

            &.name {
                white-space: normal;
                width:calc(100% - 50px);
                margin:0;
                font-size:15px;
            }

            &.thumb {
                display:none;
            }

            &.action {
                width:50px;
                font-size:20px;
                text-align: right;

                > div {
                    &.download {
                        display:none;
                    }
                }
            }
        }
    }
}

.mycalendar2_datepicker {
    .closed {
        background-color:#00B7AD;

        &:hover {
            background-color:#00B7AD!important;

            .popup_html {
                background-color:#00B7AD!important;
            }
        }
    }
}

.page_header_banner{
    padding-top: 60px;
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

.footer_logotypes{
    background: $white;
    text-align: center;
    img{
        height: 60px;
        width: auto;
    }
}

.contact_image{
    width: 100%;
    height: 0;
    padding-bottom: 66%;
    @include bg_pos;
    background-size: cover;
    background-image: url("./../img/contact_img.jpg");
    margin-bottom: 30px;
}

.holder{
    select{
        width: 100%;
    }
}

.select2-container--default {
    text-align: left;
    &.select2-container--open{
        .select2-selection__arrow{
            -moz-transform: scale(1, -1);
            -webkit-transform: scale(1, -1);
            -o-transform: scale(1, -1);
            -ms-transform: scale(1, -1);
            transform: scale(1, -1);
        }
    }
    .select2-selection--single {
        border: 1px solid $border_color;
        height: 55px;
        border-radius: 0;
        outline: none;
        background: #f6fafb;
        color: $font_color2;
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        .select2-selection__rendered{
            line-height: 55px;
            padding:0 20px;
            padding-right: 90px;
        }
        .select2-selection__arrow{
            width: 80px;
            height: 100%;
            top: 0;
            right: 0;
            background-repeat: no-repeat;
            background-image: url("./../img/select_arr.png");
            background-position: right 20px center;
            background-size: 12px;
            b{
                display: none;
            }
        }
    }
    .select2-search--dropdown {
        .select2-search__field{
            background: $white;
            color: $font_color;
            border: none;
            border-bottom: 2px solid #e0e5e8;
            outline: none;
            height: 36px;
        }
    }
}

.select2-results__option{
    &.select2-results__option--highlighted{
        background-color: $accent_color2 !important;
    }
}

.select2-dropdown{
    border: 1px solid #e0e5e8;
    border-top: none;
}

.select2-container--default .select2-results__option[aria-selected=true]{
    background-color: $accent_color1;
    color: $white;
}

.select2-results{
    padding: 0 0;
    li{
        font-weight: 600;
        font-size: 12px !important;
        padding: 10px 20px;
    }
}
